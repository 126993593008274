<service-tag>
    <div ref="service-tag"></div>
    <section class="{opts.cssClass}" if="{services.length}">
        <div class="main_title_3">
            <span><em></em></span>
            <h2><img src="/static/tiv-frontend/img/visually-verified-emblem.png" class="img-fluid"
                     alt="visually-verified-emblem" style="width: 5vh;"> {opts.title}</h2>
            <p>{opts.subtitle}</p>
        </div>
        <div class="row">

            <div class="col-xl-3 col-lg-6 col-md-6" each="{service in services}">
                <a href="/hotel/{service.id}/{service.slug}"
                   class="grid_item"
                   title="{ service.name }">
                    <figure>
                        <div class="score">
                            <img src="/static/tiv-frontend/img/visually-verified-emblem.png" class="img-thumbnail"
                                 alt="visually-verified-emblem" style="width: 4vh;">
                        </div>
                        <img src="/static/tiv-frontend/img/image-placeholder.png" data-src="{ service.image }"
                             class="img-fluid service-images"
                             alt="{ service.name }" style="height: 30vh;" ref="service-images">
                        <div class="info">
                            <div class="cat_star">
                                <i class="icon_star" each="{compileCategory(service.attrs.hotel_category)}"></i>
                            </div>
                            <h3>{ service.name }</h3>
                            <button class="btn_1 full-width purchase">
                                Book Now
                            </button>
                        </div>
                    </figure>
                </a>
            </div>

        </div>
        <!-- /row -->
        <!--        <a href="https://www.agoda.com/country/{opts.country.toLocaleLowerCase()}.html?cid=1824192"-->
        <!--           title="Explore more at agoda.com" target="_blank"><strong>Explore more at agoda.com<i-->
        <!--                class="arrow_carrot-right"></i></strong></a>-->

        <a href="{opts.canonicalUrl}"
           title="Explore more in {opts.country}" target="_blank"><strong>Explore more in {opts.country}<i
                class="arrow_carrot-right"></i></strong></a>
    </section>

    <script>
        import {debounce} from "../../../utilities/helpers.es6";

        const serviceTag = this;

        serviceTag.services = [];

        serviceTag.countries = [
            {name: 'Greece', code: 'GR'},
            {name: 'Italy', code: 'IT'},
            {name: 'France', code: 'FR'},
            {name: 'Spain', code: 'ES'},
            {name: 'Turkey', code: 'TR'},
            {name: 'Portugal', code: 'PT'},
            {name: 'Monaco', code: 'MC'},
            {name: 'Malta', code: 'MT'},
        ];

        serviceTag.on('mount', () => {
            serviceTag.observer = new IntersectionObserver(([entry]) => {
                if (entry.isIntersecting) {
                    debounce(serviceTag.loadServices(), 200);
                }
            });

            serviceTag.observer.observe(serviceTag.refs['service-tag']);
        });

        serviceTag.getCountryCode = (countryName) => {
            return serviceTag.countries.find(country => country.name === countryName);
        };

        serviceTag.on('updated', () => {
            serviceTag.observer.unobserve(serviceTag.refs['service-tag']);
            serviceTag.lazyLoadImages();
        });

        serviceTag.lazyLoadImages = () => {
            let images = $('.service-images');
            images.each((index, image) => {
                image.src = image.dataset.src;
                $(image).removeClass('service-images');
            });
        };

        serviceTag.loadServices = () => {
            $.getJSON(`${opts.apiUrl}`).done((response) => {
                serviceTag.services = response.results;
                // serviceTag.services = response.results.filter((item) => {
                //     if (item.service_type.id === 4 && item.visually_verified === true) {
                //         return item;
                //     }
                // });
                serviceTag.update();
            }).fail((error) => {
                console.log(error);
            })
        };

        serviceTag.compileCategory = (category) => {
            return Array.from(Array(parseInt(category)).keys());
        };

    </script>
</service-tag>