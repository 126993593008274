<entity-list>
    <div class="container-fluid margin_60_0" ref="entity-list">
        <div class="main_title_2">
            <span><em></em></span>
            <h2>{opts.title}</h2>
            <p>{opts.subtitle}</p>
        </div>

        <div class="isotope-wrapper">
            <div class="row">
                <div class="col-12 mb-4" if="{!isLoaded}">
                    <div class="loader ml-auto mr-auto"></div>
                </div>
                <div class="col-xl-3 col-lg-6 col-md-6 isotope-item popular mb-3 mt-3" each="{entity in entities}">
                    <div class="element-container">
                        <div class="box_grid element-box-item-description">
                            <figure>
                                <a href="" class="wish_bt {checkIfFavorite(entity)}" onclick="{addFavorite}"></a>
                                <a href="{createUrl(entity.entity_type.name,entity.id,entity.slug)}"><img
                                        ref="entity-images"
                                        src="/static/tiv-frontend/img/image-placeholder.png" data-src="{ entity.image }"
                                        class="img-fluid entity-images"
                                        alt="{ entity.multilingual_name }"
                                        width="800" height="533">
                                    <div class="read_more"><span>{getTranslations('read_more')}</span></div>
                                </a>
                                <small>{entity.primary_tag.tag.name}</small>
                            </figure>
                            <div class="wrapper">
                                <h3>
                                    <a href="{createUrl(entity.entity_type.name,entity.id,entity.slug)}"
                                       class="d-flex align-items-center">
                                        <i class="tf-{entity.entity_type.name}" style="font-size: 2em;"></i>
                                        {entity.multilingual_name}</a>
                                </h3>
                                <a href="{entity.parent_url}">{entity.breadcrumb}</a>
                                <p style="margin-top: 15px; margin-bottom: 15px;">{
                                    descriptionFormat(entity.multilingual_description, 140) }</p>
                                <span class="price">{getTranslations('basic_characteristics')}</span>
                                <br>
                                <a href="/explore/places?entity_type_name={entity.entity_type.name}&tag_name={tag.tag.name}"
                                   title="explore {tag.tag.name} {entity.entity_type.name}"
                                   each="{tag in entity.tags}">
                                    <strong style="color: #0054a6">{createHashTag(tag)}</strong>
                                </a>
                            </div>
                        </div>
                        <div class="box_grid element-box-item-stats">
                            <ul>
                                <li>
                                    <span class="badge rating-widget"
                                          data-value='{entity.rating_stats.users} {getTranslations("Users")}'
                                          style="color: white; background-color: #0054a6; font-size: 1.2rem">{entity.rating_stats.total}
                                    </span>
                                </li>
                                <li><i class="icon-eye-1"></i> {entity.views} {getTranslations('views')}</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <!-- /box_grid -->
            </div>
            <!-- /row -->
        </div>
        <!-- /carousel -->
        <div class="container mt-4">
            <p class="text-center">
                <a href="{opts.buttonUrl}"
                   class="btn_1 rounded"
                   title="{opts.buttonText}">{opts.buttonText}</a>
            </p>
        </div>
        <!-- /container -->
        <!--        <hr class="large">-->
    </div>

    <script>
        import './tags.css';
        import {debounce, getTranslations} from "../../utilities/helpers.es6";

        const entityList = this;

        entityList.getTranslations = getTranslations;

        entityList.entities = [];
        entityList.isLoaded = false;

        entityList.on('mount', () => {
            entityList.observer = new IntersectionObserver(([entry]) => {
                if (entry.isIntersecting) {
                    debounce(entityList.loadEntities(), 200);
                }
            });

            entityList.observer.observe(entityList.refs['entity-list']);
        });

        entityList.on('updated', () => {
            entityList.observer.unobserve(entityList.refs['entity-list']);
            entityList.lazyLoadImages();
        });

        entityList.loadEntities = () => {
            $.getJSON(`${opts.apiUrl}`).done((response) => {
                entityList.entities = response.results;
                entityList.isLoaded = true;
                entityList.update();
            });
        };

        entityList.lazyLoadImages = () => {
            let images = $('.entity-images');
            images.each((index, image) => {
                image.src = image.dataset.src;
                $(image).removeClass('entity-images');
            });
        };

        entityList.descriptionFormat = (text, length) => {
            let formattedText = text.replace(/(<([^>]+)>)/ig, "").split("");
            if (formattedText.length > length) {
                return formattedText.splice(0, length).join("") + '...'
            }
            return formattedText.join("")
        };

        entityList.createUrl = (entity_type, entity_id, entity_slug) => {
            if (entity_type === 'destination')
                return `/${entity_type}/${entity_id}/${entity_slug}`;
            return `/places/${entity_type}/${entity_id}/${entity_slug}`
        };

        entityList.createTagsString = (tags, delimiter) => {
            return tags.map(tag => tag.tag.name).join(delimiter)
        };

        // entityList.createHashTag = (tags) => {
        //     return tags.map(tag => `#${tag.tag.name}`.replace(/ +/g, "")).join(' ')
        // };

        entityList.createHashTag = (tag) => {
            return `#${tag.tag.name}`.replace(/ +/g, "");
        };

        entityList.addFavorite = (event) => {
            let favoriteButton = $(event.target);
            event.preventUpdate = true;
            event.preventDefault();

            $.ajax({
                url: '/members/api/v1.1/favorites/',
                type: 'POST',
                data: {favorite_id: event.item.entity.id, category: 'entity'}
            }).done(() => {
                favoriteButton.toggleClass('active');
            }).fail((error => {
                if (error.status)
                    window.open('/members/login', '_blank');
            }));
        };

        entityList.checkIfFavorite = (entity) => {
            let userFavorites = window.user.favorite_set;
            let findFavorite = userFavorites.find(favorite => favorite.favorite_id === entity.id);
            if (findFavorite)
                return 'active'
        };
    </script>
</entity-list>