<search-bar>
    <form>
        <div id="custom-search-input">
            <div class="input-group">
                <input type="text" class="search-query"
                       placeholder="{getTranslations('input_place_holder')}" oninput="{getSuggestions}"
                       ref="searchInput"
                       onfocusout="{clearSuggestions}" onfocusin="{getSuggestions}">
                <input type="submit" class="btn_search" value="{getTranslations('submit')}"
                       onclick="{search}" id="home-button-search">
            </div>
            <div class="list-group text-left" if={suggestions.length}
                 style="position: absolute;width: 100%; z-index: 100001 !important; cursor: pointer; box-shadow: 0 15px 46px -10px  rgba(26, 26, 29, 0.3);">

                <a href="{createUrl(suggestion.category, suggestion.type_name, suggestion.id, suggestion.slug)}"
                   class="list-group-item list-group-item-action" style="border: 0;"
                   each="{suggestion in suggestions}">
                    <img src="{suggestion.pin_icon}"
                         alt="{suggestion.type_name}"
                         style="width: 4%; height: 4%">
                    <span class="font-weight-bold">{suggestion.multilingual_name}</span>
                    <small>{suggestion.breadcrumb}</small>
                </a>
            </div>
        </div>
    </form>

    <script>
        import {getTranslations} from "../../utilities/helpers.es6";

        const searchBar = this;
        searchBar.getTranslations = getTranslations;
        searchBar.suggestions = [];

        searchBar.on('mount', () => {

        });

        searchBar.on('updated', () => {
        });

        searchBar.getSuggestions = () => {
            let value = searchBar.refs.searchInput.value;

            if (value)
                $.getJSON('/v1/search', {text: value, limit: 12})
                    .done((response) => {
                        searchBar.suggestions = response.results;
                        searchBar.update();
                    })
                    .fail((error) => {
                        console.log('Search Error: ', error);
                    });

            searchBar.suggestions = [];
            searchBar.update();
        };

        searchBar.search = (event) => {
            event.preventDefault();
            if (searchBar.suggestions.length) {
                let first_entity = searchBar.suggestions[0];
                window.location.href = searchBar.createUrl(first_entity.category, first_entity.type_name, first_entity.id, first_entity.slug);
            }
        };

        searchBar.clearSuggestions = (event) => {
            if (!event.relatedTarget) {
                setTimeout(() => {
                    searchBar.suggestions = [];
                    searchBar.update();
                }, 500)

            }
        };

        searchBar.createUrl = (category, type_name, id, slug) => {
            if (category === 'service') {
                return `/${type_name}/${id}/${slug}`;
            }

            if (type_name === 'destination') {
                return `/${type_name}/${id}/${slug}`;
            } else {
                return `/places/${type_name}/${id}/${slug}`
            }
        };
    </script>
</search-bar>