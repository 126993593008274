<app>
    <section class="hero_single version_2" style="z-index: 100000 !important; overflow: initial !important;">
        <div class="wrapper" style="height: 100% !important;">
            <div class="container">
                <h3>{getTranslations('header_title', true)}</h3>
                <p>{getTranslations('header_sub')}</p>
                <search-bar></search-bar>
            </div>
            <div class="container-fluid" style="position:absolute; bottom: 5%;">
                <div class="row">
                    <div class="col-xs-12 col-xl-1">
                        <a href="/presentation" class="btn btn-primary" id="home-map-search-btn" title="map search">
                            <i class="tf-our_picks" style="font-size: 3em"></i>
                            <br>
                            {getTranslations('map_search_button')}
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- /hero_single -->

    <div class="bg_color_1" style="z-index: -100000 !important;">
        <div class="container-fluid margin_60_35">
            <div class="main_title_2">
                <span><em></em></span>
                <h3>{getTranslations('explore_tripinview')}</h3>
                <p>{getTranslations('get_inspired')}</p>
            </div>
            <div class="row flex-row flex-nowrap m-0" style="max-width: 100%; overflow-x: auto; overflow-y: hidden;">
                <div class="col-6 col-xl-2">
                    <a href="/explore/services"
                       class="boxed_list vacation-style-container p-0" title="explore hotels">
                        <i class="tf-hotel explore-icons"></i>
                        <!--                        <img src="https://d2wzo7cpkz5upz.cloudfront.net/photos2/05-Elafonissos/SSS/_WIP0444.jpg"-->
                        <!--                             alt="explore hotels"-->
                        <!--                             class="img-fluid w-100 mb-1">-->
                        <h4 class="p-2">{getTranslations('explore_hotels')}</h4>
                    </a>
                </div>
                <div class="col-6 col-xl-2">
                    <a href="/explore/places?entity_type_name=destination"
                       class="boxed_list vacation-style-container p-0" title="explore destinations">
                        <i class="tf-destination explore-icons"></i>
                        <!--                        <img src="https://d2wzo7cpkz5upz.cloudfront.net/photos2/05-Elafonissos/SSS/_WIP0444.jpg"-->
                        <!--                             alt="explore destinations"-->
                        <!--                             class="img-fluid w-100 mb-1">-->
                        <h4 class="p-2">{getTranslations('explore_destinations')}</h4>
                    </a>
                </div>
                <div class="col-6 col-xl-2">
                    <a href="/explore/places?entity_type_name=beach"
                       class="boxed_list vacation-style-container p-0" title="explore beaches">
                        <i class="tf-beach explore-icons"></i>
<!--                        <img src="https://d2wzo7cpkz5upz.cloudfront.net/photos2/20150615_10-01_TR_Kalka-TR_Fethiye/SSS/10-01_WIDE_W2IP1721.jpg"-->
<!--                             alt="explore beaches"-->
<!--                             class="img-fluid w-100 mb-1">-->
                        <h4 class="p-2">{getTranslations('explore_beaches')}</h4>
                    </a>
                </div>
                <div class="col-6 col-xl-2">
                    <a href="/explore/places?entity_type_name=anchorage"
                       class="boxed_list vacation-style-container p-0" title="explore anchorages">
                        <i class="tf-anchorage explore-icons"></i>
<!--                        <img src="https://d2wzo7cpkz5upz.cloudfront.net/photos2/Kerkyra_2/SSS/_WIP9137.jpg"-->
<!--                             alt="explore anchorages"-->
<!--                             class="img-fluid w-100 mb-1">-->
                        <h4 class="p-2">{getTranslations('explore_anchorages')}</h4>
                    </a>
                </div>
                <div class="col-6 col-xl-2">
                    <a href="/explore/places?entity_type_name=marina"
                       class="boxed_list vacation-style-container p-0" title="explore marinas">
                        <i class="tf-marina explore-icons"></i>
<!--                        <img src="https://d2wzo7cpkz5upz.cloudfront.net/photos2/megara_marathonas_1/SSS/WWIP6329.jpg"-->
<!--                             class="img-fluid w-100 mb-1" alt="explore marinas">-->
                        <h4 class="p-2">{getTranslations('explore_marinas')}</h4>
                    </a>
                </div>
                <div class="col-6 col-xl-2">
                    <a href="/explore/places?entity_type_name=residential"
                       class="boxed_list vacation-style-container p-0" title="explore villages">
                        <i class="tf-residential explore-icons"></i>
<!--                        <img src="https://d2wzo7cpkz5upz.cloudfront.net/photos2/Kerkyra_2/SSS/_WIP8344.jpg"-->
<!--                             alt="explore villages"-->
<!--                             class="img-fluid w-100 mb-1">-->
                        <h4 class="p-2">{getTranslations('explore_villages')}</h4>
                    </a>
                </div>
                <div class="col-6 col-xl-2">
                    <a href="/explore/places?entity_type_name=port"
                       class="boxed_list vacation-style-container p-0" title="explore ports">
                        <i class="tf-port explore-icons"></i>
<!--                        <img src="https://d2wzo7cpkz5upz.cloudfront.net/photos2/20140902-665-01_FR_Nice-FR_Dramont/SSS/0665-01_TELE_TIP_6496.jpg"-->
<!--                             alt="explore ports"-->
<!--                             class="img-fluid w-100 mb-1">-->
                        <h4 class="p-2">{getTranslations('explore_ports')}</h4>
                    </a>
                </div>
            </div>
            <!-- /row -->
        </div>
        <!-- /container -->
    </div>
    <!-- /bg_color_1 -->

    <entity-list
            title="{getTranslations('popular_destinations_title', true)}"
            subtitle="{getTranslations('popular_destinations_sub')}"
            button-text="{getTranslations('popular_destinations_button')}"
            button-url="/explore/places/?entity_type_name=destination"
            entity-type="destination"
            api-url="/v1/entities/?shuffle=4">
    </entity-list>

    <service-container
            first-service-title="{getTranslations('visually_verified_hotels_gr_title', true)}"
            first-service-subtitle="{getTranslations('visually_verified_hotels_gr_sub')}"
            first-service-country="Greece"
            first-service-url="/v1/services/?service_type__name=hotel&country=GR&limit=4&visually_verified=true"
            first-service-canonical-url="/explore/services?country=GR"
            second-service-title="{getTranslations('visually_verified_hotels_it_title', true)}"
            second-service-subtitle="{getTranslations('visually_verified_hotels_it_sub')}"
            second-service-country="Italy"
            second-service-url="/v1/services/?service_type__name=hotel&country=IT&limit=4&visually_verified=true"
            second-service-canonical-url="/explore/services?country=IT"
    ></service-container>

    <entity-list
            title="{getTranslations('popular_beaches_gr_title', true)}"
            subtitle="{getTranslations('popular_beaches_gr_sub')}"
            button-text="{getTranslations('popular_beaches_gr_button')}"
            button-url="/explore/places/?entity_type_name=beach&country=Greece"
            entity-type="beach"
            api-url="/v1/entities/?entity_type_name=beach&country=Greece&limit=4"
    ></entity-list>

    <service-container
            first-service-title="{getTranslations('visually_verified_hotels_es_title', true)}"
            first-service-subtitle="{getTranslations('visually_verified_hotels_es_sub')}"
            first-service-country="Spain"
            first-service-url="/v1/services/?service_type__name=hotel&country=ES&limit=4&visually_verified=true"
            first-service-canonical-url="/explore/services?country=ES"
            second-service-title="{getTranslations('visually_verified_hotels_tr_title', true)}"
            second-service-subtitle="{getTranslations('visually_verified_hotels_tr_sub')}"
            second-service-country="Turkey"
            second-service-url="/v1/services/?service_type__name=hotel&country=TR&limit=4&visually_verified=true"
            second-service-canonical-url="/explore/services?country=TR"
    ></service-container>

    <entity-list
            title="{getTranslations('popular_beaches_it_title', true)}"
            subtitle="{getTranslations('popular_beaches_it_sub')}"
            button-text="{getTranslations('popular_beaches_it_button')}"
            button-url="/explore/places/?entity_type_name=beach&country=Italy"
            entity-type="beach"
            api-url="/v1/entities/?entity_type_name=beach&country=Italy&limit=4"
    ></entity-list>

    <service-container
            first-service-title="{getTranslations('visually_verified_hotels_fr_title', true)}"
            first-service-subtitle="{getTranslations('visually_verified_hotels_fr_sub')}"
            first-service-country="France"
            first-service-url="/v1/services/?service_type__name=hotel&country=FR&limit=4&visually_verified=true"
            first-service-canonical-url="/explore/services?country=FR"
            second-service-title="{getTranslations('visually_verified_hotels_mt_title', true)}"
            second-service-subtitle="{getTranslations('visually_verified_hotels_mt_sub')}"
            second-service-country="Malta"
            second-service-url="/v1/services/?service_type__name=hotel&country=MT&limit=4&visually_verified=true"
            second-service-canonical-url="/explore/services?country=MT"
    ></service-container>

    <div class="bg_color_1">
        <div class="container-fluid margin_60_35">
            <div class="main_title_2">
                <span><em></em></span>
                <h3>{getTranslations('vacation_styles_title', true)}</h3>
                <p>{getTranslations('vacation_styles_sub')}</p>
            </div>
            <div class="row justify-content-center">
                <div class="col-md-12 col-xl-2">
                    <a href="/explore/places?entity_type_name=destination&tag_name=couples,romance"
                       class="boxed_list vacation-style-container" title="{getTranslations('couples_romance')}"
                       id="home-couple-romance">
                        <img src="/static/tiv-frontend/img/style-tags/couples.png" alt="couples-style"
                             width="60" height="60" style="margin-bottom: 15px;">
                        <h4>{getTranslations('couples_romance', true)}</h4>
                        <p>{getTranslations('couples_romance_desc')}</p>
                    </a>
                </div>
                <div class="col-md-12 col-xl-2">
                    <a href="/explore/places?entity_type_name=destination&tag_name=culture"
                       class="boxed_list vacation-style-container" title="{getTranslations('culture')}"
                       id="home-culture">
                        <img src="/static/tiv-frontend/img/style-tags/culture.png" alt="culture-style"
                             width="60" height="60" style="margin-bottom: 15px;">
                        <h4>{getTranslations('culture', true)}</h4>
                        <p>{getTranslations('culture_desc')}</p>
                    </a>
                </div>
                <div class="col-md-12 col-xl-2">
                    <a href="/explore/places?entity_type_name=destination&tag_name=nightlife"
                       class="boxed_list vacation-style-container" title="{getTranslations('nightlife')}"
                       id="home-nightlife">
                        <img src="/static/tiv-frontend/img/style-tags/nightlife.png" alt="nightlife-style"
                             width="60" height="60" style="margin-bottom: 15px;">
                        <h4>{getTranslations('nightlife', true)}</h4>
                        <p>{getTranslations('nightlife_desc')}</p>
                    </a>
                </div>
                <div class="col-md-12 col-xl-2">
                    <a href="/explore/places?entity_type_name=destination&tag_name=family"
                       class="boxed_list vacation-style-container" title="{getTranslations('family')}" id="home-family">
                        <img src="/static/tiv-frontend/img/style-tags/family.png" alt="family-style"
                             width="60" height="60" style="margin-bottom: 15px;">
                        <h4>{getTranslations('family', true)}</h4>
                        <p>{getTranslations('family_desc')}</p>
                    </a>
                </div>
                <div class="col-md-12 col-xl-2">
                    <a href="/explore/places?entity_type_name=destination&tag_name=luxury"
                       class="boxed_list vacation-style-container" title="{getTranslations('luxury')}" id="home-luxury">
                        <img src="/static/tiv-frontend/img/style-tags/luxury.png" alt="luxury-style"
                             width="60" height="60" style="margin-bottom: 15px;">
                        <h4>{getTranslations('luxury', true)}</h4>
                        <p>{getTranslations('luxury_desc')}</p>
                    </a>
                </div>
                <div class="col-md-12 col-xl-2">
                    <a href="/explore/places?entity_type_name=destination&tag_name=nautical"
                       class="boxed_list vacation-style-container" title="{getTranslations('nautical')}"
                       id="home-nautical">
                        <img src="/static/tiv-frontend/img/style-tags/nautical.png" alt="nautical-style"
                             width="60" height="60" style="margin-bottom: 15px;">
                        <h4>{getTranslations('nautical', true)}</h4>
                        <p>{getTranslations('nautical_desc')}</p>
                    </a>
                </div>
            </div>
            <!-- /row -->
        </div>
        <!-- /container -->
    </div>

    <script>
        import './index.css';
        import '../tags/entity-list.tag.html';
        import '../tags/service-list-container/service-container.tag.html';
        import '../tags/search-bar.tag.html';

        import {getTranslations} from "../../utilities/helpers.es6";

        const app = this;
        app.getTranslations = getTranslations;

    </script>
</app>