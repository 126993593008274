<service-container>

    <div class="container-fluid margin_30_95 pl-lg-5 pr-lg-5" ref="service-container">

        <service-tag
                if="{opts.firstServiceUrl}"
                css-class="add_bottom_45"
                country="{opts.firstServiceCountry}"
                api-url="{opts.firstServiceUrl}"
                canonical-url="{opts.firstServiceCanonicalUrl}"
                title="{opts.firstServiceTitle}"
                subtitle="{opts.firstServiceSubtitle}"
        ></service-tag>

        <service-tag
                if="{opts.secondServiceUrl}"
                css-class=""
                country="{opts.secondServiceCountry}"
                api-url="{opts.secondServiceUrl}"
                canonical-url="{opts.secondServiceCanonicalUrl}"
                title="{opts.secondServiceTitle}"
                subtitle="{opts.secondServiceSubtitle}"
        ></service-tag>

    </div>

    <script>
        import './service-tag.html';

        const serviceContainer = this;
    </script>
</service-container>