import riot from 'riot';

export const getCookie = (name) => {
    let cookieValue = null;

    if (document.cookie && document.cookie !== '') {
        let cookies = document.cookie.split(';');

        cookies.forEach((item) => {
            let cookie = item.trim();
            if (cookie.substring(0, name.length + 1) === (name + '=')) {
                cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
            }
        });
    }
    return cookieValue;
};

export const debounce = (func, wait, immediate) => {
    let timeout;
    return () => {
        let context = this, args = arguments;
        let later = () => {
            timeout = null;
            if (!immediate) func.apply(context, args);
        };

        let callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) func.apply(context, args);
    };
};

export const getTranslations = (translationKey, header = false) => {
    let translation = window.translations[translationKey];

    if (!translation)
        return translationKey;

    if (header) {
        return translation.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    } else {
        return translation;
    }
};

export const uriCompile = (imageUri, type) => {

    if (imageUri.includes(type)) return imageUri;

    let uri_parts = imageUri.split('/');
    return [...uri_parts.slice(0, -1), type, uri_parts[uri_parts.length - 1]].join('/')
};

export const mountComponent = (containerElementId, tagElementName) => {
    let containerElement = document.getElementById(containerElementId);
    let tagElement = document.createElement(tagElementName);
    containerElement.appendChild(tagElement);
    riot.mount(tagElementName);
};

export const mountMixin = (mixinName, mixinObject) => {
    riot.mixin(mixinName, mixinObject);
};